import { useState } from "react";
import { DrawerBase, makeModal } from "../../../utils/make_modal";
import { CreatorSearch } from "./package_form";
import { PlatformIcon } from "../../campaigns/utils";
import { SubmitButton } from "../../../utils/submit_button";
import { useApiMutation } from "../../../utils/api";
import { toast } from "react-toastify";

export const useManualAddDrawer = () =>
  makeModal(
    ({
      refetch,
      close,
      campaign,
    }: {
      refetch: () => void;
      close: () => void;
      campaign: any;
    }) => {
      const [creator, setCreator] = useState<any>(null);
      const { post, saving } = useApiMutation();

      const onSubmit = (values: any) => {
        post(`${campaign._link}/deals/manual_add`, {
          creator_platform_id: creator.id,
        }).then(({ data }) => {
          if (data.success) {
            toast.success("Creator added");
            refetch();
            close();
          }
        });
      };
      const onAdd = (result: any) => {
        setCreator(result);
      };

      return (
        <DrawerBase title="Add Creator Manually" close={close}>
          {creator ? (
            <div>
              <PlatformIcon object={creator} />
              <div>
                <SubmitButton
                  type="submit"
                  loading={saving}
                  className="btn btn-primary mt-3"
                  onClick={onSubmit}
                >
                  Add Creator
                </SubmitButton>
              </div>
            </div>
          ) : (
            <>
              <div className="fw-700">Creator</div>
              <CreatorSearch
                platform={campaign.platform}
                onAdd={onAdd}
                allAddedCreators={[]}
              />
            </>
          )}
          {/*

            <SubmitButton type="submit" loading={saving} className="btn btn-primary mt-3">
              Fetch Creators
            </SubmitButton>
          </form> */}
        </DrawerBase>
      );
    }
  );
