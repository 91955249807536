import { Modal } from "react-bootstrap";
import { makeModal, ModalBase } from "../../utils/make_modal";

export const useTermsModal = () =>
  makeModal(({ close }: { close: () => void }) => {
    return (
      <ModalBase title="Terms & Conditions" close={close} className="modal-xl">
        <Modal.Body>
          <div className="terms-and-conditions">
            <div className="terms-and-conditions">
              <h2 className="mb-2">1. Campaign Participation</h2>
              <p>
                Creators may freely browse and opt-in to any campaign listed on
                XrossWorld. Once a creator submits a proposal for a campaign, they are
                committed to the project if selected by the brand. By opting in and
                submitting a proposal, creators agree to complete all required
                deliverables and adhere to the campaign guidelines as outlined in the
                campaign overview.
              </p>

              <hr />

              <h2 className="mb-2">2. Commitment to Deliverables</h2>
              <p>
                If selected for a campaign, creators are expected to fulfill all
                deliverables to the brand's specifications. Completion of all agreed-upon
                deliverables is required for payment release. Failure to complete
                deliverables may impact future opportunities, and XrossWorld reserves the
                right to limit access to future campaigns for creators who fail to fulfill
                their commitments without valid reason.
              </p>

              <hr />

              <h2 className="mb-2">3. Exclusivity Requirements</h2>
              <p>
                XrossWorld does not impose exclusivity on creators. However, some
                campaigns may include specific exclusivity requirements, which will be
                clearly listed in the campaign overview before a creator opts in. Creators
                are responsible for reviewing and agreeing to any exclusivity terms in
                campaigns they wish to join, ensuring compliance if selected.
              </p>

              <hr />

              <h2 className="mb-2">4. Campaign Requirements</h2>
              <p>
                Campaign requirements, including deliverables, usage rights, compensation
                details, exclusivity clauses, and other specific terms, will vary by
                campaign. These details will be provided in the campaign overview, and
                creators must thoroughly review this information before opting in to
                ensure they can meet all terms and obligations.
              </p>

              <hr />

              <h2 className="mb-2">5. Payment Terms</h2>
              <p>
                Payments for campaigns are processed and released only after all
                deliverables have been submitted, reviewed, and approved according to the
                campaign's guidelines. XrossWorld is not liable for delayed payments due
                to incomplete deliverables or failure to meet brand requirements.
              </p>

              <hr />

              <h2 className="mb-2">6. Content Guidelines</h2>
              <p>
                Creators are expected to represent themselves and XrossWorld with
                professionalism. Posts and content associated with campaigns must avoid
                any discriminatory, racist, offensive, or inflammatory language or
                imagery. Any content found in violation of these standards may result in
                the creator being removed from the campaign and may impact future
                participation opportunities on the platform.
              </p>

              <hr />

              <h2 className="mb-2">7. Liability</h2>
              <p>
                By opting into and committing to a campaign, creators assume
                responsibility for the timely completion of their deliverables. XrossWorld
                shall not be liable for any disputes arising between creators and brands,
                though we provide support to resolve issues within our platform's
                guidelines.
              </p>

              <hr />

              <h2 className="mb-2">8. Confidentiality and Direct Contact with Brands</h2>
              <p>
                Creators must maintain confidentiality regarding all brands and campaign
                details provided on the XrossWorld platform. Creators are strictly
                prohibited from directly contacting any brand presented through
                XrossWorld. All communications should occur solely through the platform to
                maintain professionalism and streamline interactions.
              </p>
              <p>
                Any violation of this confidentiality requirement, including unauthorized
                direct outreach to brands, may result in immediate suspension or
                termination of the creator's account on XrossWorld. This policy ensures a
                trusted and secure environment for all platform participants.
              </p>

              <hr />

              <h1 className="mt-8 mb-8 fs-24">XrossWorld Privacy Notice</h1>

              <h2 className="mb-2">1. Personal Information Collection</h2>
              <p>
                XrossWorld collects personal information, including your name, date of
                birth, email, and profile details, to match you with relevant campaign
                opportunities and communicate updates. Your data is stored securely and
                used solely for operational purposes within the XrossWorld platform.
              </p>

              <hr />

              <h2 className="mb-2">2. Data Sharing with Brands</h2>
              <p>
                When you opt into a campaign, only data relevant to your audience
                demographics and specific campaign details will be shared with the brand
                for selection purposes. We prioritize sharing only the information
                necessary to facilitate your participation in campaigns, ensuring that any
                additional personal data remains private.
              </p>

              <hr />

              <h2 className="mb-2">3. Usage Data</h2>
              <p>
                We collect and analyze usage data on our platform to improve user
                experience, optimize campaign matches, and enhance platform performance.
                All data used in analytics is aggregated and anonymized to protect
                individual privacy.
              </p>

              <hr />

              <h2 className="mb-2">4. Opting Out and Data Deletion</h2>
              <p>
                You may opt out of XrossWorld at any time, and upon request, we will
                delete your data from our systems, in accordance with legal and regulatory
                requirements.
              </p>

              <hr />

              <h2 className="mb-2">5. Security Measures</h2>
              <p>
                XrossWorld is committed to safeguarding your data. We employ
                industry-standard encryption and security practices to ensure the
                protection of personal and campaign-related information.
              </p>
            </div>

            <hr />

            <p className="version">Version: 1.0 | Last Updated: Dec 25, 2024</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={close}>
            Close
          </button>
        </Modal.Footer>
      </ModalBase>
    );
  });
