import { useQuery } from "react-query";
import axios from "axios";
import { genericError } from "./errors";
import { useState } from "react";

const buildPath = (path, params) => {
  if (!params || !Object.keys(params).length) return path;
  const sp = new URLSearchParams();
  Object.entries(params).forEach(([k, v]) =>
    Array.isArray(v) ? v.forEach((x) => sp.append(`${k}[]`, x)) : sp.append(k, String(v))
  );
  return `${path}?${sp}`;
};

const baseFetch = (path, params = null) => {
  return fetch(buildPath(path, params), {
    headers: { Accept: "application/json" },
  }).then((res) => res.json());
};

export const useApiGet = (path: string) => {
  let unavailable;
  let data;

  const q = useQuery(path, () => baseFetch(path));

  if (q.isFetching && !q.isFetched) {
    unavailable = <div></div>;
  } else if (q.error) {
    unavailable = <div>There was an error</div>;
    genericError();
  } else if (q.isSuccess) {
    data = q.data;
  }

  return {
    data,
    unavailable,
    loading: q.isFetching,
    refetch: q.refetch,
  };
};

export const usePageData = () => {
  return useApiGet(location.pathname + location.search);
};

export const useApiGetLive = (path, params = {}, config = {}) => {
  const q = useQuery([path, params], () => baseFetch(path, params), {
    keepPreviousData: true,
    ...config,
  });
  return {
    data: q.data,
    refetch: q.refetch,
    loading: q.isFetching,
    loaded: q.isSuccess,
  };
};

export const useApiMutation = () => {
  let callback;

  const [saving, setSaving] = useState(false);

  const makeRequest =
    (method: "put" | "post" | "delete") =>
    (path: string, data = {}, config = {}) => {
      setSaving(true);
      const promise = axios[method](path, data, config);
      promise.catch(genericError);
      promise.finally(() => setSaving(false));
      promise.then(({ data }) => callback?.(data));
      return promise;
    };

  const put = makeRequest("put");
  const post = makeRequest("post");
  const destroy = makeRequest("delete");

  return { post, put, destroy, saving };
};
