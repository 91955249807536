type DealStatus =
  | "accepted_offer"
  | "countered"
  | "connected"
  | "invited"
  | "matched"
  | "manually_added";

export const dealStatuses = [
  "accepted_offer",
  "countered",
  "connected",
  "invited",
  "uninvited",
  "manually_added",
];

export const dealStatusBadge = (status: DealStatus) => {
  return {
    accepted_offer: <span className="badge text-bg-warning">Accepted</span>,
    countered: <span className="badge text-bg-warning">Countered</span>,
    connected: <span className="badge text-bg-info">Account created</span>,
    invited: <span className="badge text-bg-light">Email Sent</span>,
    uninvited: <span className="badge text-bg-light">Matched</span>,
    manually_added: <span className="badge text-bg-light">Manually added</span>,
  }[status || "uninvited"];
};

export const dealStatusesForAdmin = [
  { value: "accepted_offer", label: "Accepted" },
  { value: "countered", label: "Countered" },
  { value: "connected", label: "Account created" },
  { value: "invited", label: "Email sent" },
  { value: "uninvited", label: "Matched" },
  { value: "manually_added", label: "Manually added" },
];
