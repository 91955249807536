import { DrawerBase, makeModal } from "../../../utils/make_modal";
import { toast } from "react-toastify";
import { useApiMutation } from "../../../utils/api";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitButton } from "../../../utils/submit_button";
import { z } from "zod";

const isProd = (window as any).env.environment === "production";
const environments = isProd ? ["prod"] : ["sandbox", "staging", "prod"];

const schema = z.object({
  followers_max: z.number().min(1).default(10000),
  followers_min: z.number().min(1).default(5000),
  count: z.number().min(1).default(1),
  account: z.string().default(isProd ? "prod" : "sandbox"),
  has_email: z.boolean().default(true),
  use_gender: z.boolean().default(true),
  use_categories: z.boolean().default(true),
});

type FormValues = z.infer<typeof schema>;

export const useFetchCreatorsDrawer = () =>
  makeModal(
    ({
      refetch,
      close,
      campaign,
    }: {
      refetch: () => void;
      close: () => void;
      campaign: any;
    }) => {
      const { post, saving } = useApiMutation();
      const { register, handleSubmit } = useForm<FormValues>({
        resolver: zodResolver(schema),
        defaultValues: schema.parse({
          use_gender: ["male", "female"].includes(
            campaign.creator_gender.toLowerCase()
          ),
        }),
      });

      const onSubmit = (values: FormValues) => {
        const loadingToast = toast.loading(
          "Please wait while we fetch creators.  This can take up to 5 minutes."
        );
        post(`${campaign._link}/fetch_creators`, values).then(({ data }) => {
          if (data.success) {
            toast.update(loadingToast, {
              render: "Creators fetched",
              type: "success",
              isLoading: false,
              autoClose: 3000, // Close after 3 sec
            });
            refetch();
            close();
          } else {
            toast.update(loadingToast, {
              render: data.user_error || "Something went wrong",
              type: "error",
              isLoading: false,
              autoClose: 5000, // Close after 5 sec
            });
          }
        });
      };

      return (
        <DrawerBase title="Fetch Creators" close={close}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="alert alert-warning">
              Note that if ENVIRONMENT is set to PROD, Xrossworld will be billed
              per creator.
            </div>
            <div>
              <label className="form-label">Number of creators to fetch</label>
              <select
                {...register("count", { valueAsNumber: true })}
                className="form-select"
              >
                {[
                  1, 100, 250, 500, 1000, 1500, 2000, 3000, 4000, 5000, 6000,
                ].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
            </div>
            {!isProd && (
              <div className="mt-3">
                <label className="form-label">Environment</label>
                <select
                  {...register("account")}
                  className="form-select"
                  disabled={isProd}
                >
                  {environments.map((account) => (
                    <option key={account} value={account}>
                      {account}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="row mt-3 g-3">
              <label className="form-label mt-0">Followers (Min - Max)</label>
              <div className="col mt-0">
                <input
                  type="number"
                  {...register("followers_min", { valueAsNumber: true })}
                  className="form-control"
                  required
                />
              </div>
              <div className="col mt-0">
                <input
                  type="number"
                  {...register("followers_max", { valueAsNumber: true })}
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  {...register("has_email")}
                  className="form-check-input"
                  id="hasEmail"
                />
                <label className="form-check-label" htmlFor="hasEmail">
                  Require Email
                </label>
              </div>
            </div>
            <div className="mt-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  {...register("use_gender")}
                  className="form-check-input"
                  id="useGender"
                  disabled={
                    !["male", "female"].includes(
                      campaign.creator_gender.toLowerCase()
                    )
                  }
                />
                <label className="form-check-label" htmlFor="useGender">
                  Require Gender ({campaign.creator_gender})
                </label>
              </div>
            </div>
            <div className="mt-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  {...register("use_categories")}
                  className="form-check-input"
                  id="useCategories"
                />
                <label className="form-check-label" htmlFor="useCategories">
                  Require Categories ({campaign.categories.join(", ")})
                </label>
              </div>
            </div>

            <SubmitButton
              type="submit"
              loading={saving}
              className="btn btn-primary mt-3"
            >
              Fetch Creators
            </SubmitButton>
          </form>
        </DrawerBase>
      );
    }
  );
