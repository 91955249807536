import { Modal } from "react-bootstrap";
import { makeModal, ModalBase } from "../../utils/make_modal";

export const useTermsModal = () =>
  makeModal(({ close }: { close: () => void }) => {
    return (
      <ModalBase title="Terms & Conditions" close={close} className="modal-xl">
        <Modal.Body>
          <div className="terms-and-conditions">
            <h1 className="mb-4 fs-24">1. Introduction</h1>
            <p>
              By purchasing a campaign package through Xrossworld, you, as the client of
              the marketing agency, acknowledge that the campaign package constitutes an
              advertising spend, designed for media buying and campaign execution, and
              does not establish a service or contracting relationship between the client,
              Xrossworld, the Agency, or the creators. agree to these Terms and
              Conditions. This agreement governs your relationship with Leben & Cerne Corp
              DBA Xrossworld ("Xrossworld") and outlines your rights and obligations as
              the agency client.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">2. Campaign Package Purchase</h1>
            <p>
              <strong>2.1 Purchase Commitment:</strong> By accepting a campaign package,
              you agree to purchase the services as described in the package and outlined
              in the campaign brief. The purchase of a campaign package constitutes an
              advertising spend for media buying and campaign execution. It does not
              establish a service or contracting relationship between the client and
              Xrossworld, the Agency, or the creators. Funds are allocated exclusively to
              the execution of the campaign as described in the campaign brief.
            </p>
            <p>
              <strong>2.2 Non-Refundable Fees:</strong> Technology Service Fees (5.8%) and
              Talent Management Fees (3.8%) are non-refundable. Additionally, the package
              fee will be held in reserve and not distributed to creators or applied to
              the Talent Management Fee until the campaign deliverables have been 100%
              successfully completed and verified by the Agency.
            </p>
            <p>
              <strong>2.3 Unforeseen Circumstances:</strong> In the unlikely event that
              creators are unable to fully complete a campaign due to unforeseen
              circumstances, Xrossworld Technology will first attempt to replace the
              creators at no extra charge to the brand. If no suitable replacements are
              available, the brand may be eligible for a full refund of the creators'
              portion of the package fee, excluding the Talent Management Fee and
              Xrossworld Technology Fee.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">3. Payment Terms</h1>
            <p>
              <strong>3.1 Full Payment Required:</strong> Payment in full is required
              before the campaign can commence. Payments must be made within 10 days of
              receiving the invoice to ensure creator availability.
            </p>
            <p>
              <strong>3.2 Fund Management:</strong> The funds for the campaign package
              will be held in a protected account by Xrossworld and safeguarded by
              Xrossworld Tech Insurance.
            </p>
            <p>
              <strong>3.3 Payout Conditions:</strong> Funds will be released to creators
              and the agency only after all campaign deliverables are 100% completed and
              verified.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">4. Campaign Deliverables and Responsibilities</h1>
            <p>
              <strong>4.1 Approval Process:</strong> The client, in collaboration with the
              Agency, is responsible for approving content submitted by creators within
              the specified timeframe as outlined in the campaign brief. Both parties must
              ensure timely feedback to avoid delays in the campaign schedule.
            </p>
            <p>
              <strong>4.2 Timelines:</strong> Delays in approvals may impact the campaign
              schedule, and Xrossworld is not liable for such delays.
            </p>
            <p>
              <strong>4.3 Deliverable Revisions:</strong> Clients may request up to two
              rounds of revisions for each piece of submitted content.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">5. Creator Payments and Tax Documentation</h1>
            <p>
              <strong>5.1 Creator Payment Terms:</strong> Creators can only cash out their
              campaign fees after they have fully completed and verified all deliverables
              as outlined in the campaign brief. In the unlikely event that a creator
              cannot complete their deliverables, the creator will not be paid for the
              incomplete campaign.
            </p>
            <p>
              <strong>5.2 Tax Compliance:</strong> Neither the client nor the Agency is
              responsible for paying creators directly or collecting any legal paperwork,
              such as W9 forms or tax documentation. All creator payments and compliance
              with legal and tax requirements are managed exclusively by Xrossworld.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">6. Fund Protection and Insurance</h1>
            <p>
              <strong>6.1 Fund Security:</strong> All campaign funds are protected under
              Xrossworld Tech Insurance to ensure they are allocated appropriately and
              released only upon successful completion of campaign objectives.
            </p>
            <p>
              <strong>6.2 Disbursement Policy:</strong> Funds are disbursed to creators
              and the agency only after campaign deliverables are completed and verified.
              This ensures accountability and alignment with campaign goals.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">7. Non-Circumvention Clause</h1>
            <p>
              <strong>7.1 Engagement Restriction:</strong> The client agrees not to engage
              creators identified through Xrossworld for campaigns outside the platform
              for a period of 36 months.
            </p>
            <p>
              <strong>7.2 Penalty for Breach:</strong> Violations of this clause will
              result in a penalty of $10,000,000 per creator.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">
              8. Creator Verification, Brand Safety, and Compliance
            </h1>
            <p>
              <strong>8.1</strong> Xrossworld Technology takes significant measures to
              verify that creators are real individuals with active and engaged audiences
              to drive the best possible campaign outcomes. This includes ensuring that
              creators comply with United States regulations and are legally authorized to
              work on such projects.
            </p>
            <p>
              <strong>8.2</strong> Xrossworld reviews creator profiles and past posts to
              ensure alignment with brand safety standards, avoiding content that is
              politically driven, racist, discriminatory, or otherwise harmful to the
              client's reputation.
            </p>
            <p>
              <strong>8.3</strong> In the unlikely event that Xrossworld Technology misses
              a past post from an active or inactive social media handle or profile and
              the brand becomes aware of this, it is the brand's responsibility to inform
              the marketing agency so the creator can be replaced. Xrossworld cannot
              guarantee the ability to find and vet deactivated social media handles with
              past posts, especially if those profiles were not created under the
              creator's legal name.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">9. Limitation of Liability</h1>
            <p>Xrossworld is not liable for:</p>
            <ul>
              <li>
                Failure to meet campaign performance metrics unless explicitly guaranteed
                in the campaign brief.
              </li>
              <li>Delays or non-performance by creators.</li>
              <li>
                Technical issues related to Xrossworld technology that are outside of
                reasonable control.
              </li>
            </ul>

            <hr />

            <h1 className="mb-4 fs-24">10. Cancellation and Refund Policy</h1>
            <p>
              <strong>10.1 Cancellation:</strong> Cancellations made within 5 business
              days of payment may qualify for a refund of up to 94.2% of the campaign
              package cost.
            </p>
            <p>
              <strong>10.2 Non-Refundable Fees:</strong> Technology Service Fees and
              Talent Management Fees are non-refundable.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">11. Content Ownership and Licensing</h1>
            <p>
              <strong>11.1 License to Content:</strong> Upon campaign completion and
              payment, the client will be granted usage rights to the campaign content as
              specified by the brand's marketing agency in the campaign brief. The brand
              is not allowed to use the content in any other form without the explicit
              authorization of the creator.
            </p>
            <p>
              <strong>11.2 Content Rights:</strong> Creators retain ownership of all
              content unless otherwise explicitly agreed upon in writing. Full payment
              must be received before any usage rights are granted to the client.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">12. Confidentiality and Non-Disclosure</h1>
            <p>
              <strong>12.1</strong> Both parties agree to maintain confidentiality
              regarding proprietary information shared during the campaign. This includes
              campaign strategies, creator insights, and financial data, unless required
              by law or mutually agreed upon in writing.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">13. Intellectual Property Rights</h1>
            <p>
              <strong>13.1</strong> All intellectual property rights arising from the
              services provided to the client, as outlined in the campaign brief, become
              the property of the client upon full payment, unless explicitly stated
              otherwise.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">14. Force Majeure</h1>
            <p>
              <strong>14.1</strong> Neither party shall be held liable for delays or
              non-performance caused by events beyond their reasonable control, including
              natural disasters, pandemics, government actions, or other unforeseen
              circumstances.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">15. Dispute Resolution</h1>
            <p>
              <strong>15.1</strong> In the event of a dispute, both parties agree to
              attempt mediation or arbitration before pursuing legal action. The governing
              law and jurisdiction will be [Insert Jurisdiction].
            </p>

            <hr />

            <h1 className="mb-4 fs-24">16. Amendment and Waiver</h1>
            <p>
              <strong>16.1</strong> Any amendments or waivers to this agreement must be
              made in writing and signed by both parties to be effective.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">17. Severability</h1>
            <p>
              <strong>17.1</strong> If any provision of this agreement is deemed
              unenforceable, the remaining provisions will remain in full effect.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">18. Entire Agreement</h1>
            <p>
              <strong>18.1</strong> This agreement constitutes the complete understanding
              between the parties and supersedes all prior discussions, agreements, or
              understandings.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">19. Ownership and Copyright</h1>
            <p>
              <strong>19.1</strong> This document and its contents, including all text,
              structure, and associated intellectual property, are the sole property of
              Leben & Cerne Corp DBA Xrossworld.
            </p>
            <p>
              <strong>19.2</strong> Unauthorized use, duplication, or modification of this
              document without explicit written permission is prohibited and may result in
              legal action.
            </p>
            <p>
              <strong>19.3</strong> All rights not expressly granted in this document are
              reserved by Leben & Cerne Corp DBA Xrossworld.
            </p>

            <hr />

            <h1 className="mb-4 fs-24">20. Acceptance and Amendments</h1>
            <p>
              <strong>20.1</strong> By purchasing a campaign package, you acknowledge that
              you have read, understood, and agreed to these Terms and Conditions.
            </p>
            <p>
              <strong>20.2</strong> Xrossworld reserves the right to update these Terms
              and Conditions with notice provided to clients.
            </p>

            <hr />

            <p className="version">Version: 1.0 | Last Updated: Dec 25, 2024</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={close}>
            Close
          </button>
        </Modal.Footer>
      </ModalBase>
    );
  });
