import { last } from "lodash";

export const getCampaignSteps = () => {
  const steps = [
    { key: "platform" },
    { key: "creator_profile" },
    { key: "creator_matches" },
    { key: "campaign_brief" },
    { key: "creator_brief" },
    { key: "budget" },
    { key: "review" },
  ];

  const currentPath = last(location.pathname.split("/"));

  const currentStepIndex = steps.findIndex((s) => s.key === currentPath);

  const prev = steps[currentStepIndex - 1]?.key;
  const current = steps[currentStepIndex]?.key;
  const next = steps[currentStepIndex + 1]?.key;

  return { prev, current, next };
};
