import { useApiMutation } from "../../utils/api";
import { ModalBody } from "react-bootstrap";
import { makeModal, ModalBase } from "../../utils/make_modal";
import { SubmitButton } from "../../utils/submit_button";
import { TextField } from "../campaigns/fields";
import { useFormik } from "formik";
import { genericError } from "../../utils/errors";
import * as Yup from "yup";

const segmentData = {
  nano: [100, 1000],
  micro: [500, 10000],
  mid_tier: [2500, 20000],
  mega: [5000, 30000],
  celebrity: [10000, 50000],
};

const segments = [
  { key: "below_market", label: "Below Market" },
  { key: "cost_efficient", label: "Cost Efficient" },
  { key: "fair", label: "Fair Value" },
  { key: "premium", label: "Premium Price" },
];

const getSegment = (tier: string | null, value: number) => {
  if (!tier) {
    return null;
  }

  const [low, high] = segmentData[tier];

  if (value <= low) {
    return segments[0];
  } else if (value <= (low + high) / 2) {
    return segments[1];
  } else if (value <= high) {
    return segments[2];
  } else {
    return segments[3];
  }
};

export const useCreatorOfferModal = () =>
  makeModal(
    ({ refetch, close, deal }: { refetch: () => void; close: () => void; deal: any }) => {
      const { post, saving } = useApiMutation();
      const formik = useFormik({
        initialValues: { creator_offer: null },
        validationSchema: Yup.object({
          creator_offer: Yup.number().required("Required").min(1, "Required"),
        }),
        onSubmit: (values) => {
          post(`${deal._link}/set_creator_offer`, {
            creator_offer: values.creator_offer,
          }).then(({ data }) => {
            if (data.success) {
              refetch();
              close();
            } else {
              genericError();
            }
          });
        },
      });

      const segment = getSegment(deal.creator_platform.size, formik.values.creator_offer);

      return (
        <ModalBase title="Set your offer" close={close}>
          <ModalBody>
            <form onSubmit={formik.handleSubmit} className="">
              <label className="fs-20">Set your campaign price</label>
              <div className="flex align-items-center">
                <TextField
                  name="creator_offer"
                  type="number"
                  formik={formik}
                  inputClassName="fs-36"
                  prepend={<div className="fs-36">$</div>}
                />
              </div>

              {segment?.key === "premium" && (
                <div className="text-danger mt-1">
                  Premium price (may not be selected for campaign)
                </div>
              )}

              <SubmitButton
                className="btn btn-primary w-100 mt-6"
                type="submit"
                loading={saving}
              >
                Propose offer
              </SubmitButton>
            </form>
          </ModalBody>
        </ModalBase>
      );
    }
  );
