import { useNavigate } from "react-router-dom";
import { useApiMutation } from "../../utils/api";
import { CheckboxField } from "../campaigns/fields";
import { useFormik } from "formik";
import { SubmitButton } from "../../utils/submit_button";
import { genericError } from "../../utils/errors";
import { useTermsModal } from "./terms_modal";

const Nda = () => {
  const navigate = useNavigate();
  const { post, saving } = useApiMutation();
  const TermsModal = useTermsModal();

  const formik = useFormik({
    initialValues: { agree: false },
    onSubmit: (values) => {
      if (values.agree) {
        post("/creators/registrations/accept_nda", values).then(({ data }) => {
          if (data.success) {
            navigate("/creators");
          } else {
            genericError();
          }
        });
      }
    },
  });

  return (
    <div>
      <h2 className="mt-4">Review and Agree to Terms</h2>

      <p className="mt-4">
        You’re almost ready to explore campaigns that match your profile. To ensure a
        smooth experience, please take a moment to review and agree to our{" "}
        <button className="btn-text link-like" onClick={() => TermsModal.show()}>
          Terms and Conditions and Privacy Policy
        </button>
        . By selecting "I Agree," you confirm that you've read and accepted the terms.
      </p>
      <p className="mt-2">
        If you have any questions about these terms, you can reach out to your campaign
        representative, or{" "}
        <a href="mailto:support@xrossworld.ai" className="link-like">
          support@xrossworld.ai
        </a>
      </p>

      <form className="mt-4" onSubmit={formik.handleSubmit}>
        <CheckboxField
          label="I agree with the above"
          name="agree"
          required
          formik={formik}
        />

        <SubmitButton
          className="btn btn-primary mt-4 w-100"
          type="submit"
          loading={saving}
        >
          Continue
        </SubmitButton>
      </form>

      <TermsModal />
    </div>
  );
};

export default Nda;
