import { Link } from "react-router-dom";
import { usePageData } from "../../utils/api";
import { PlatformIcon } from "../campaigns/utils";
import { usePhylloConnectModal } from "./phyllo_connect_modal";
import { useState } from "react";
import { LoaderCircle } from "lucide-react";

const Platforms = () => {
  const { data, unavailable, loading, refetch } = usePageData();
  const [pending, setPending] = useState(false);
  const PhylloConnectModal = usePhylloConnectModal();

  if (unavailable) {
    return unavailable;
  }

  const { creator_platforms } = data;

  return (
    <div>
      <p>
        Please connect your social platforms to apply for brand campaigns. This helps us
        match you with more opportunities that best fit your audience and engagement.
      </p>

      <div className="card mt-6">
        <div className="card-body flex space-between align-items-center fw-600">
          <div>Connect Platforms</div>
          <button onClick={PhylloConnectModal.show} className="btn btn-primary">
            Connect
          </button>
        </div>
      </div>

      <h2 className="mt-6 mb-3">My Platforms</h2>

      {creator_platforms.length === 0 && !pending && !loading && (
        <div className="alert alert-warning mt-2" role="alert">
          No platforms connected. Connect your social accounts now to get matched with
          opportunities.
        </div>
      )}

      {creator_platforms.map((p) => (
        <div key={p.uuid}>
          <div className="flex space-between">
            <div>
              <PlatformIcon platform={p.platform} handle={p.handle} />
            </div>
            <div className="green">Connected</div>
          </div>
          <hr />
        </div>
      ))}

      {pending && (
        <div className="text-center mt-1">
          <LoaderCircle className="spin" size={40} />
        </div>
      )}

      <Link to="/creators" className="mt-6 d-block text-center">
        Back to Dashboard
      </Link>

      <PhylloConnectModal refetch={refetch} setPending={setPending} />
    </div>
  );
};

export default Platforms;
