import { useState } from "react";
import { Link } from "react-router-dom";
import { useApiGetLive, useApiMutation } from "../../../utils/api";
import { ButtonMenu, EllipsisMenu } from "../../../utils/ellipsis_menu";
import { PlatformIcon, SizeBadge } from "../../campaigns/utils";
import DataTable from "../data_table";
import { dealStatusBadge, dealStatusesForAdmin } from "../../../utils/deal_status";
import { formatCurrency, formatNummber } from "../../../utils/helpers";
import { useBulkInviteDrawer } from "./invite_bulk_drawer";
import { useSingleInviteDrawer } from "./invite_single_drawer";
import { useEditDealDrawer } from "./edit_deal_drawer";
import { useFetchCreatorsDrawer } from "./fetch_creators_drawer";
import { useManualAddDrawer } from "./manual_add_drawer";
import { capitalize } from "lodash";
import { toast } from "react-toastify";
import { genericError } from "../../../utils/errors";

const emptyFilters = {
  sort: "handle",
  sortDir: "asc",
  page: 1,
};

const offerTier = (creatorOffer: number, creatorPlatformSize: string) => {
  if (!creatorOffer) return null;

  const priceRanges = {
    nano: [100, 550, 1000],
    micro: [500, 5250, 10000],
    "mid-tier": [2500, 11250, 20000],
    mega: [5000, 17500, 30000],
    celebrity: [10000, 30000, 50000],
  };

  const tiers = ["Below Market", "Cost Efficient", "Fair Value", "Premium Price"];
  const ranges = priceRanges[creatorPlatformSize];

  const index = ranges.findIndex((price) => creatorOffer < price);
  return tiers[index === -1 ? tiers.length - 1 : index];
};

export const DealsTab = ({ campaign }: { campaign: any }) => {
  const defaultFilters: any = { ...emptyFilters };
  const params = new URLSearchParams(window.location.search);
  const view = params.get("view");

  if (view === "offers") {
    defaultFilters.deal_status = ["accepted_offer", "countered"];
    defaultFilters.sort = "edited";
    defaultFilters.sortDir = "desc";
  }

  const [filters, setFilters] = useState(defaultFilters);
  const { data, loaded, loading, refetch } = useApiGetLive(
    `${campaign._link}/deals`,
    filters
  );
  const BulkInviteDrawer = useBulkInviteDrawer();
  const SingleInviteDrawer = useSingleInviteDrawer();
  const EditDealDrawer = useEditDealDrawer();
  const FetchCreatorsDrawer = useFetchCreatorsDrawer();
  const ManualAddDrawer = useManualAddDrawer();

  const { post, destroy } = useApiMutation();

  const deleteAllDeals = () => {
    if (confirm("Are you sure you want to delete all deals?")) {
      post(`/admin/campaigns/${campaign.uuid}/delete_all_deals`).then(() => {
        document.location.reload();
      });
    }
  };

  const deleteDeal = (deal: any) => {
    if (
      confirm("Are you sure you want to delete this deal?") &&
      ["uninvited", "manually_added"].includes(deal.status)
    ) {
      destroy(`/admin/campaigns/${campaign.uuid}/deals/${deal.uuid}`).then(() => {
        toast.success("Creator removed");
        refetch();
      });
    }
  };

  const syncMetrics = (deal: any) => {
    post(
      `/admin/creators/${deal.creator_uuid}/creator_platforms/${deal.creator_platform_uuid}/sync_metrics`
    ).then(({ data }) => {
      if (data.success) {
        toast.success("Engagement metrics updated");
        refetch();
      } else {
        genericError();
      }
    });
  };

  const { results, meta } = data || {};

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <div className="mb-5 buttons">
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            onClick={() => FetchCreatorsDrawer.show()}
          >
            Fetch Creators
          </button>

          <button
            type="button"
            className="btn btn-sm btn-outline-primary ml-1"
            onClick={() => BulkInviteDrawer.show()}
          >
            Send Invite Email
          </button>

          <ButtonMenu
            label="More"
            className="ml-1"
            actions={[
              {
                label: "Add Creator Manually",
                action: () => ManualAddDrawer.show(),
              },
              { separator: true },
              {
                label: "Delete All Deals",
                action: () => deleteAllDeals(),
                className: "text-danger",
              },
            ]}
            name="more"
          />
        </div>
      </div>

      {loaded && (
        <DataTable
          schema={[
            {
              name: "name",
              header: "Creator",
              value: (p) => (
                <Link to={`/admin/creators/${p.creator_uuid}`}>
                  {p.name || <span className="red">&lt;no name&gt;</span>}
                </Link>
              ),
            },
            {
              name: "handle",
              value: (p) => (
                <div className="white-space-pre">
                  <PlatformIcon platform={p.platform} />
                  <a href={p.link} target="_blank" className="ml-2">
                    {p.handle}
                    <i className="bi bi-box-arrow-up-right gray ml-1 fs-10 vertical-align-middle" />
                  </a>
                </div>
              ),
            },
            {
              name: "email",
              header: "Has email",
              value: (p) => (p.email ? "Yes" : "--"),
            },
            { name: "followers", type: "number" },
            { name: "size", value: (p) => <SizeBadge size={p.size} /> },
            { name: "country", value: "country" },
            { name: "gender", value: (p) => capitalize(p.gender) },
            {
              name: "impressions",
              header: "Exp. Plays",
              type: "number",
              value: (p) => formatNummber(p.impressions),
            },
            {
              name: "interactions",
              header: "Exp. Interactions",
              type: "number",
              value: (p) => formatNummber(p.interactions),
            },
            {
              name: "offer",
              value: (p) =>
                p.offer && (
                  <div>
                    {formatCurrency(p.offer)}
                    <div className="ml-1 fs-12 text-muted white-space-pre">
                      {offerTier(p.offer, p.size)}
                    </div>
                  </div>
                ),
            },
            { name: "status", value: (p) => dealStatusBadge(p.status) },
            {
              name: "edited",
              value: (p) => new Date(p.updated_at).toLocaleDateString(),
            },
            {
              name: "",
              header: (
                <EllipsisMenu
                  horizontal
                  actions={[
                    {
                      label: "Download CSV",
                      link: `${campaign._link}/deals/csv`,
                      target: "_blank",
                    },
                  ]}
                />
              ),
              value: (p) => (
                <EllipsisMenu
                  actions={[
                    {
                      label: "Campaign Invitation",
                      action: () => SingleInviteDrawer.set(p),
                    },
                    {
                      label: "Edit Deal",
                      action: () => EditDealDrawer.set(p),
                      if: !campaign.packages_submitted_at,
                    },
                    {
                      label: "Fetch Metrics",
                      action: () => syncMetrics(p),
                      if: p.offer,
                    },
                    {
                      separator: true,
                      if: ["uninvited", "manually_added"].includes(p.status),
                    },
                    {
                      label: "Delete",
                      className: "text-danger",
                      action: () => deleteDeal(p),
                      if: ["uninvited", "manually_added"].includes(p.status),
                    },
                  ]}
                />
              ),
              sortable: false,
            },
          ]}
          data={results}
          filterControls={[
            { name: "search", type: "search" },
            {
              name: "deal_status",
              placeholder: "Status",
              options: dealStatusesForAdmin,
            },
          ]}
          filters={filters}
          setFilters={setFilters}
          reset={() => setFilters(emptyFilters)}
          sortable
          meta={meta}
          isFetching={loading}
        />
      )}

      <BulkInviteDrawer refetch={refetch} />
      <SingleInviteDrawer refetch={refetch} />
      <EditDealDrawer refetch={refetch} />
      <FetchCreatorsDrawer refetch={refetch} campaign={campaign} />
      <ManualAddDrawer refetch={refetch} campaign={campaign} />
    </>
  );
};
