import { Link } from "react-router-dom";
import { useApiGetLive, useApiMutation } from "../../../utils/api";
import { PlatformIcon } from "../../campaigns/utils";
import DataTable from "../data_table";
import { useState } from "react";
import { platforms } from "../../campaigns/lists";
import { capitalize, startCase } from "lodash";
import { EllipsisMenu } from "../../../utils/ellipsis_menu";
import { genericError } from "../../../utils/errors";
import { makeModal, ModalBase } from "../../../utils/make_modal";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

const emptyFilters = {
  sort: "updated",
  sortDir: "desc",
  page: 1,
};

const useConfirmDeleteModal = () => {
  return makeModal(
    ({
      object: campaign,
      refetch,
      close,
    }: {
      object: any;
      refetch: () => void;
      close: () => void;
    }) => {
      const { destroy } = useApiMutation();

      const deleteCampaign = () => {
        destroy(campaign._link).then(({ data }) => {
          if (data.success) {
            toast("Campaign deleted");
            close();
            refetch();
          } else {
            genericError();
          }
        });
      };

      return (
        <ModalBase title="Delete Campaign" close={close}>
          <Modal.Body>
            <div>Are you sure you want to delete this campaign?</div>
            <div className="mt-3">
              <div>{campaign.name}</div>
              <div>{campaign.brand?.name}</div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button className="btn btn-outline-secondary" onClick={close}>
              Cancel
            </button>
            <button className="btn btn-danger" onClick={deleteCampaign}>
              Delete
            </button>
          </Modal.Footer>
        </ModalBase>
      );
    }
  );
};

const AdminHomeIndex = () => {
  const [filters, setFilters] = useState(emptyFilters);
  const ConfirmDeleteModal = useConfirmDeleteModal();

  const { data, refetch, loaded, loading } = useApiGetLive(`/admin/campaigns`, filters);

  const { campaigns, meta, filter_options } = data || {};

  return (
    <div id="main">
      <h2 className="fs-2_5r">Manage Campaigns</h2>

      {loaded && (
        <DataTable
          data={campaigns}
          schema={[
            {
              name: "name",
              value: (r) => <Link to={r._link}>{r.name}</Link>,
            },
            {
              name: "platform",
              value: (r) => (
                <PlatformIcon platform={r.platform} handle={capitalize(r.platform)} />
              ),
            },
            { name: "brand", value: "brand.name" },
            { name: "agency", value: "agency.name" },
            { name: "status", value: (r) => startCase(r.status) },
            {
              name: "updated",
              value: (r) => (
                <>
                  <div>{r.last_updated_info.at}</div>
                  <div className="fs-14 gray">{r.last_updated_info.by}</div>
                </>
              ),
            },
            {
              name: "view",
              header: "",
              value: (r) => (
                <EllipsisMenu
                  actions={[
                    { label: "View campaign details", link: r._link },
                    { label: "View campaign creators", link: r._link },
                    { separator: true, if: r["deleteable?"] },
                    {
                      label: "Delete campaign",
                      action: () => ConfirmDeleteModal.set(r),
                      className: "red",
                      if: r["deleteable?"],
                    },
                  ]}
                />
              ),
            },
          ]}
          filterControls={[
            { name: "search", type: "search" },
            { name: "platform", options: platforms },
            { name: "brand", options: filter_options.brand },
            { name: "agency", options: filter_options.agency },
            { name: "status", options: filter_options.status },
          ]}
          filters={filters}
          setFilters={setFilters}
          reset={() => setFilters(emptyFilters)}
          sortable
          meta={meta}
          widths={[300, 200, 300, null, null, null, 50]}
          isFetching={loading}
        />
      )}

      <ConfirmDeleteModal refetch={refetch} />
    </div>
  );
};

export default AdminHomeIndex;
