import Header from "./header";
import CampaignInfo from "./steps_side_nav";
import { useCampaign, useShowOnboardingNav } from "./utils";
import {
  DateField,
  MultiSelect,
  SelectField,
  TextArea,
  TextField,
  Validators,
} from "./fields";
import ActionButtons from "./action_buttons";
import AddBrand from "./brand_form";
import { useApiGet } from "../../utils/api";
import { useFormikHandler } from "./form_handler";
import { Drawer } from "../../utils/offcanvas";
import { useState } from "react";
import { HelpLinkDrawer } from "../../utils/offcanvas";
import { QuestionTooltip } from "./tooltip";
import Faqs from "./faq";
import { isEmpty } from "lodash";

const faqs = [
  {
    title: "What information should I include in the campaign description?",
    body: "In the campaign description, include the overall purpose of your campaign, key messages, and any creative direction you'd like the creators to follow. This helps ensure that the creators align with your vision and deliver content that meets your goals.",
  },
  {
    title: "Why do I need to select success metrics (KPIs) for my campaign?",
    body: "Selecting key performance indicators (KPIs) helps measure the success of your campaign. These metrics, such as impressions, engagement, or conversions, will guide the platform’s performance tracking and ensure that your campaign goals are met.",
  },
  {
    title: "Can I change my campaign details before I review and submit?",
    body: "Yes, you can edit your campaign details, including the campaign name, brand, success metrics, and description, before you review and submit the campaign. Once you've finalized and submitted the campaign for launch, changes may be limited.",
  },
];

const daysAfterDate = (date: string | undefined, days: number) => {
  if (!isEmpty(date)) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString().slice(0, 10);
  }
};

const SuccessMetricsHelp = () => {
  return (
    <>
      <dl>
        <dt>Increase Brand Awareness</dt>
        <dd>Reach, Impressions, Brand Mentions, Audience Growth</dd>

        <dt>Drive Website Traffic</dt>
        <dd>Click-Through Rate (CTR), Link Clicks, Cost Per Click (CPC)</dd>

        <dt>Boost Engagement</dt>
        <dd>
          Engagement Rate (likes, comments, shares), Content Shares, Follower Growth
        </dd>

        <dt>Increase Sales / Conversions</dt>
        <dd>
          Conversion Rate, Return on Ad Spend (ROAS), Cost Per Acquisition (CPA), Sales
          Revenue
        </dd>

        <dt>Generate Leads</dt>
        <dd>Conversion Rate, Cost Per Lead (CPL), Link Clicks, Lead Submissions</dd>

        <dt>Product Launch</dt>
        <dd>
          Reach, Impressions, Engagement Rate, Conversion Rate, User-Generated Content
          (UGC)
        </dd>

        <dt>Collect User-Generated Content (UGC)</dt>
        <dd>Number of UGC Pieces, Engagement Rate, Content Shares</dd>
      </dl>
    </>
  );
};

const CampaignBrief = () => {
  useShowOnboardingNav();
  const { campaign, unavailable } = useCampaign();
  const { data: brandsData } = useApiGet("/brands");
  const [showBrandForm, setShowBrandForm] = useState(false);

  const formik = useFormikHandler({
    campaign,
    fields: [
      "name",
      "brand_id",
      "objectives",
      "about",
      "posting_start_date",
      "posting_end_date",
    ],
    validators: {
      name: Validators.required,
      brand_id: Validators.required,
      objectives: Validators.atLeastOneInGroup,
      about: Validators.required,
      posting_start_date: Validators.required,
      posting_end_date: Validators.required,
    },
  });

  if (unavailable) {
    return unavailable;
  }

  const { brands } = brandsData || {};

  return (
    <div id="main" className="container">
      <Header percent={40} />
      <CampaignInfo campaign={campaign} />

      <div className="row">
        <div className="col">
          <div className="page-subhead mb-4">
            <h1>Set up Your Campaign Brief</h1>
            <h2>
              Enter key details about your campaign and the product or service you're
              promoting.
            </h2>
          </div>

          <form onSubmit={formik.handleSubmit} className="form-container">
            <TextField
              label="Campaign name"
              name="name"
              sublabel="Create a unique campaign name to easily identify this project."
              formik={formik}
            />

            <SelectField
              label="Brand"
              sublabel="Select or add the brand this campaign will represent."
              name="brand_id"
              className="mt-4"
              options={[
                { value: "_add_", label: "Add new brand" },
                { value: "", label: "-------------" },
                ...(brands?.map((b) => ({ label: b.name, value: b.id })) || []),
              ]}
              formik={formik}
              placeholder=""
              onChange={(e) => {
                if (e.target.value === "_add_") {
                  setShowBrandForm(true);
                  e.target.value = "";
                }
              }}
            />

            <div className="mt-4">
              <label>Success metrics</label>
              <div className="form-text">
                Choose up to 3 key metrics (KPIs) to measure your campaign's success.{" "}
                <HelpLinkDrawer
                  title="About KPI Options"
                  text="About KPI options"
                  content={<SuccessMetricsHelp />}
                  closeButton
                />
              </div>

              <MultiSelect
                options={[
                  "Increase Brand Awareness",
                  "Drive Website Traffic",
                  "Boost Engagement",
                  "Increase Sales / Conversions",
                  "Generate Leads",
                  "Product Launch",
                  "Collect User-Generated Content (UGC)",
                ]}
                name="objectives"
                formik={formik}
              />
            </div>

            <div className="mt-4">
              <TextArea
                label="About the campaign"
                sublabel="Describe your campaign's purpose and any creative direction for the creators."
                name="about"
                placeholder="What do you want the creators to know about your campaign. Include any creative direction here."
                formik={formik}
              />
            </div>

            <div className="mt-4 mb-4 col-6 row">
              <div className="col-6">
                <DateField
                  label={
                    <>
                      Posting start date
                      <QuestionTooltip tip="Earliest date creators can post.  Note that this date is based on 7 days content production and 2 days for approvals.  Revisions may delay timeline." />
                    </>
                  }
                  name="posting_start_date"
                  min={daysAfterDate(new Date().toISOString(), 21)}
                  formik={formik}
                />
              </div>

              <div className="col-6">
                <DateField
                  label={
                    <>
                      Posting end date
                      <QuestionTooltip tip="Latest date creators can post.  Minimum 7 days from start date and no more than 30 days from start date" />
                    </>
                  }
                  name="posting_end_date"
                  disabled={isEmpty(formik.values.posting_start_date)}
                  min={daysAfterDate(formik.values.posting_start_date, 7)}
                  max={daysAfterDate(formik.values.posting_start_date, 30)}
                  formik={formik}
                />
              </div>
            </div>

            <ActionButtons saving={formik.saving} />

            <Faqs faqs={faqs} />
          </form>
        </div>
      </div>

      <Drawer
        show={showBrandForm}
        onHide={() => setShowBrandForm(false)}
        title="Add Brand"
      >
        <AddBrand
          onSuccess={(newId) => {
            brands.refetch();
            formik.setFieldValue("brand_id", newId);
          }}
          close={() => setShowBrandForm(false)}
        />
      </Drawer>
    </div>
  );
};

export default CampaignBrief;
