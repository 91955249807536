import { LoaderCircle } from "lucide-react";
import { usePageData } from "../../../utils/api";
import { DrawerBase, makeModal } from "../../../utils/make_modal";

const useDetailsDrawer = () =>
  makeModal(
    ({
      refetch,
      close,
      object,
    }: {
      refetch: () => void;
      close: () => void;
      object: any;
    }) => {
      return (
        <DrawerBase title="InsightIQ" close={close}>
          <div className="fs-12 white-space-break">{JSON.stringify(object, null, 2)}</div>
        </DrawerBase>
      );
    }
  );

const tables = {
  creator_platforms: {
    title: "Creator Platforms",
    data: "creator_platforms",
    columns: [
      { key: "uuid", header: "UUID" },
      { key: "handle", header: "Handle" },
      { key: "platform", header: "Platform" },
    ],
  },
  // users: {
  //   title: "Users",
  //   data: "users",
  //   columns: [
  //     { key: "id", header: "ID" },
  //     { key: "name", header: "Name" },
  //   ],
  // },
  // profiles: {
  //   title: "Profiles",
  //   data: "profiles",
  //   columns: [
  //     { key: "id", header: "ID" },
  //     { key: "work_platform.name", header: "Platform" },
  //     { key: "username", header: "Username" },
  //     { key: "user.name", header: "Name" },
  //   ],
  // },
  // accounts: {
  //   title: "Accounts",
  //   data: "accounts",
  //   columns: [
  //     { key: "id", header: "ID" },
  //     { key: "work_platform.name", header: "Platform" },
  //     { key: "username", header: "Username" },
  //     { key: "user.name", header: "Name" },
  //   ],
  // },
};

const AdminInsightIq = () => {
  const { data, loading } = usePageData();
  const DetailsDrawer = useDetailsDrawer();

  if (loading) {
    return <LoaderCircle className="spin" size={32} />;
  }

  const getValue = (obj: any, path: string) => {
    return path.split(".").reduce((acc, part) => acc?.[part], obj);
  };

  console.log("data", data);

  return (
    <div>
      <h1>InsightIQ</h1>

      {Object.entries(tables).map(([key, table]) => (
        <div className="mt-5" key={key}>
          <h2>{table.title}</h2>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                {table.columns.map((column) => (
                  <th key={column.header}>{column.header}</th>
                ))}
                <th />
              </tr>
            </thead>
            <tbody>
              {data[table.data].map((item) => (
                <tr key={item.id}>
                  {table.columns.map((column) => (
                    <td key={column.header}>{getValue(item, column.key)}</td>
                  ))}
                  <td>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => DetailsDrawer.set(item)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}

      <DetailsDrawer />
    </div>
  );
};

export default AdminInsightIq;
