import { Link, useNavigate } from "react-router-dom";
import { useApiMutation, usePageData } from "../../utils/api";
import { genericError } from "../../utils/errors";
import Faqs from "./faq";
import { SubmitButton } from "../../utils/submit_button";
import { formatCurrency } from "../../utils/helpers";
import { useTermsModal } from "./terms_modal";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";

const faqs = [
  {
    title: "When will my campaign officially start?",
    body: "Your campaign will officially start once payment is confirmed and creators are notified. You can expect updates on content creation and timelines through your dashboard.",
  },
  {
    title: "How do I track my campaign progress?",
    body: "After payment is confirmed, you'll be able to track all key aspects of your campaign—creator performance, content approvals, and campaign metrics—directly from your dashboard in real-time.",
  },
  {
    title: "Can I request a refund after purchasing a package?",
    body: "Refunds are only available in specific cases, such as if your campaign cannot be executed due to unforeseen issues with creator availability. Once a campaign has started and creators have begun work, refunds are generally not issued. If you have any concerns, please reach out to our support team for further assistance.",
  },
];

const CheckoutBase = () => {
  const { data, unavailable } = usePageData();
  const { post, put } = useApiMutation();
  const TermsModal = useTermsModal();
  const stripe = useStripe();
  const elements = useElements();
  const [saving, setSaving] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const navigate = useNavigate();

  if (unavailable) {
    return unavailable;
  }

  const { package: pkg } = data;

  const readOnly = !!pkg.campaign.selected_package?.uuid;

  const checkout = async (event) => {
    event.preventDefault();
    setSaving(true);

    const cardElement = elements.getElement(CardElement);

    try {
      const { client_secret, error } = (await post(`${pkg._link}/payment_transaction`))
        .data;

      if (error) {
        genericError();
        return;
      }

      const { paymentIntent, error: stripeError } = await stripe.confirmCardPayment(
        client_secret,
        { payment_method: { card: cardElement } }
      );
      toast.error(error);

      if (stripeError) {
        if (["validation_error", "card_error"].includes(stripeError.type)) {
          setStripeError(stripeError.message);
        } else {
          console.log(stripeError);
          genericError();
        }
        return;
      } else if (paymentIntent.status === "succeeded") {
        await put(`${pkg._link}/payment_transaction`);
        navigate(`/campaigns`);
      }
    } catch (error) {
      genericError();
    } finally {
      setSaving(false);
    }
  };

  const {
    management_fee_amount,
    management_fee_rate,
    subtotal,
    technology_service_fee_amount,
    technology_service_fee_rate,
  } = pkg.checkout_costs;

  return (
    <form id="main" className="container pb-10" onSubmit={(e) => checkout(e)}>
      <div className="row my-5 pb-3 border-bottom">
        <div className="col text-center">
          <div className="text-center fw-400 mb-1r">
            <Link to="/campaigns">{pkg.campaign.name}</Link>
          </div>
          <h1>Checkout</h1>
          <h4 className="fw-normal mt-2">We accept credit cards and ACH transfers</h4>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="page-subhead">
            <h3>Billing Info</h3>
            <p className="mt-1">{pkg.campaign.agency.name}</p>
          </div>

          <div className="page-subhead mt-10">
            <h3>Your Order</h3>
            <table className="table mt-2">
              <thead>
                <tr>
                  <th>Item</th>
                  <th className="text-right">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href={pkg._link} className="fs-5">
                      Package {pkg.index}
                    </a>
                    <br />
                    <span className="small text-muted">
                      {pkg.creators_count} creators, {pkg.creators_count} total content
                    </span>
                  </td>
                  <td className="text-right">{formatCurrency(pkg.cost, true)}</td>
                </tr>
                <tr>
                  <td>
                    {pkg.campaign.agency.name} Talent Management Fee (
                    {management_fee_rate}%)
                  </td>
                  <td className="text-right">
                    {management_fee_amount === 0
                      ? "*FREE"
                      : formatCurrency(management_fee_amount, true)}
                  </td>
                </tr>
                <tr>
                  <td>
                    Xrossworld Technology Service Fee ({technology_service_fee_rate}%)
                  </td>
                  <td className="text-right">
                    {formatCurrency(technology_service_fee_amount, true)}
                  </td>
                </tr>
                <tr className="divider-top" style={{ borderTopWidth: 4 }}>
                  <th>Total</th>
                  <td className="text-right fw-bold">{formatCurrency(subtotal, true)}</td>
                </tr>
              </tbody>
            </table>
            {management_fee_amount === 0 && (
              <p className="text-muted small">* Free during BETA</p>
            )}

            {!readOnly && (
              <>
                <h3 className="mt-3 mb-3">Payment Info</h3>
                <form onSubmit={checkout} className="mxw-600px">
                  <CardElement
                    className="form-control p-3 bg-white"
                    onChange={() => setStripeError(null)}
                  />
                  {stripeError && <div className="text-danger">{stripeError}</div>}
                </form>
                <div className="form-check mt-3">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="agree"
                      value=""
                      required
                    />
                    I agree to the{" "}
                    <a
                      className="link-like"
                      onClick={(e) => {
                        e.preventDefault();
                        TermsModal.show();
                      }}
                    >
                      Terms &amp; Conditions
                    </a>{" "}
                    of this site
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="mt-10">
        {readOnly ? (
          <div className="text-center">
            <Link to="/campaigns" className="btn btn-xw-secondary btn-lg margin-auto">
              Back
            </Link>
          </div>
        ) : (
          <>
            <div className="row row-cta">
              <div className="col">
                <div className="page-actions mt-2">
                  <div className="cta">
                    <a href={pkg._link} className="btn btn-xw-secondary btn-lg">
                      Back
                    </a>
                    <SubmitButton className="btn btn-xw-primary btn-lg" loading={saving}>
                      Purchase Package
                    </SubmitButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5">
              <div className="col px-5">
                <h4 className="text-center fw-normal">What's Next</h4>

                <p>
                  Once your payment is confirmed, your selected creators will be notified
                  and start working on your campaign. You’ll be able to track campaign
                  performance, monitor content creation and approvals, and review key
                  metrics in real-time from your dashboard.
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      <Faqs faqs={faqs} />

      <TermsModal />
    </form>
  );
};

const Checkout = () => {
  const stripePromise = useMemo(
    () => loadStripe((window as any).env.stripe_publishable_key),
    []
  );

  return (
    <Elements stripe={stripePromise}>
      <CheckoutBase />
    </Elements>
  );
};

export default Checkout;
