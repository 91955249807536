export const formatNummber = (amount: number) =>
  amount == null ? "" : amount.toLocaleString();

export const formatCurrency = (amount: number, includeCents = false) =>
  amount == null
    ? ""
    : `$${amount.toLocaleString(undefined, {
        minimumFractionDigits: includeCents ? 2 : 0,
        maximumFractionDigits: includeCents ? 2 : 0,
      })}`;
